exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-custom-page-jsx": () => import("./../../../src/templates/custom-page.jsx" /* webpackChunkName: "component---src-templates-custom-page-jsx" */),
  "component---src-templates-general-page-jsx": () => import("./../../../src/templates/general-page.jsx" /* webpackChunkName: "component---src-templates-general-page-jsx" */),
  "component---src-templates-map-page-jsx": () => import("./../../../src/templates/map-page.jsx" /* webpackChunkName: "component---src-templates-map-page-jsx" */),
  "component---src-templates-project-listing-page-jsx": () => import("./../../../src/templates/project-listing-page.jsx" /* webpackChunkName: "component---src-templates-project-listing-page-jsx" */),
  "component---src-templates-school-listing-page-jsx": () => import("./../../../src/templates/school-listing-page.jsx" /* webpackChunkName: "component---src-templates-school-listing-page-jsx" */)
}

