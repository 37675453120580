import { PrismicLink } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Fragment, useState } from 'react';
import ActionLink from '../ActionLink';
import { useMeta } from '../providers/MetaProvider';

import * as s from './Header.module.scss';

const Header = () => {
  const { meta } = useMeta();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (

    <header className={s.header}>
      <a href="#main" className={s.skipLink}>Skip to content</a>
      <nav className={s.navBar} aria-label="main nav"> 
        <PrismicLink href={'/'} className={s.logo}>
          <GatsbyImage 
            image={meta.site_logo?.gatsbyImageData} 
            alt={meta.site_logo?.alt || ''} 
          />
        </PrismicLink>

        <ul className={`${s.linkslist} ${isMobileMenuOpen ? s.open : ''}`}>
        {
          meta.navigation_links.map((link, index) => 
          (
            <li key={index}>
              <Fragment>
                <ActionLink
                  link={link.btn_link.url}
                  label={link.btn_label}
                  type={link.btn_type || 'Plain'}
                  aria-current={link.btn_label === 'Home' ? 'page' : null}
                />
              </Fragment>
            </li>
          ))
        }
        </ul>
        {/* mobile menu button */}
        <button 
          className={s.mobileMenuButton} 
          aria-label="Open mobile menu"
          onClick={toggleMobileMenu}
          aria-expanded={isMobileMenuOpen}
          >
          <span>{meta.menu_label}</span>
        </button>
      </nav>
    </header>
  );
};

export default Header;