import React, { createContext, useContext } from "react";

const MetaContext = createContext();

export const MetaProvider = ({ children, meta, labels }) => {
  
  return (
    <MetaContext.Provider value={{meta, labels}}>
      {children}
    </MetaContext.Provider>
  );
};

export const useMeta = () => useContext(MetaContext);