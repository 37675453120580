import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useFilter } from '../providers/FilterProvider';
import * as s from './FilterBar.module.scss';
import { FaFilter, FaSearch } from 'react-icons/fa';
import clsx from 'clsx';
import { useMeta } from '../providers/MetaProvider';

const FilterBar = () => {
  const {handleFilterChange, handleSearchChange, filterOptions, filters, searchTerm, handleClear} = useFilter();
  const {labels} = useMeta();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  function selectPlaceholder(id){
    const filterBy = labels.filter_by?.replace('{{x}}', id) || `Filter by ${id}`;
    const filterLength = filters[id].length;
    const filterLengthString = filterLength > 0 ? ' ('+filterLength+')' : '';
    console.log('filterBy', filterBy + filterLengthString)
    return filterBy + filterLengthString;
  }
  return (
    <>
      <div className={
        clsx(s.FilterWrapDesktop
          , isExpanded ? s.FilterWrapMobile : s.FilterWrapHidden)
        }>
        <div className={s.FilterBar}>
          <Search 
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={labels.search}
          />
          {filterOptions.map(({id,options}) => (
              <MultiSelect 
                key={id}
                onChange={(value) => handleFilterChange(id, value)}
                filter={filters[id]}
                className={s.SelectInput}
                options={options.map(option => ({value: option, label: option}))}
                placeholder={selectPlaceholder(id)}
              />
            )
          )}
          <div className={s.BtnWrap}>
            <button onClick={handleClear} className={s.buttonSecondary}>{labels.reset}</button>
            <button onClick={handleToggle} className={clsx(s.buttonPrimary, s.DoneButton)}>{labels.done}</button>
          </div>
          
        </div>
      </div>

      <button onClick={handleToggle} className={clsx(s.buttonPrimary, s.ExpandButton)}>{labels.filter} <FaFilter/></button>
    </>
  );
};

export default FilterBar;

const Search = ({value, onChange, placeholder}) => {
  return (
    <span className={s.SearchInputWrap}>
      <FaSearch className={s.SearchIcon} />
      <label htmlFor="aria-search-input" className={s.SearchLabel}>Search</label>
      <input
        className={s.SearchInput}
        type="text"
        value={value}
        onChange={onChange}
        aria-describedby="results"
        role="searchbox"
        id="aria-search-input"
        placeholder={placeholder}
      />
    </span>
  );
};
const MultiSelect = ({options, onChange, filter, placeholder}) => {
  const { Option } = components;
  const selectedOptions = options.filter(option => filter.includes(option.value));
  const CustomOption = (props) => (
    <Option {...props}>

      {props.isSelected ? '☑ ' : '☐ '}{props.label}
    </Option>
  );

  return (

    <Select
      isMulti
      options={options}
      isSearchable={false}
      onChange={onChange}
      aria-labelledby="aria-label-category"
      inputId="aria-category-input"
      isClearable={false}
      className={s.SelectInput}
      classNamePrefix="react-select"
      classNames={{
        control:() =>  s.SelectControl,
        dropdownIndicator:() =>  s.SelectDropdownIndicator,
      }}
      placeholder={placeholder} 
      components={{ Option: CustomOption }}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      value={selectedOptions}
    />
  );
};