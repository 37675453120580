import * as React from "react";
import Header from "./header";
import { MetaProvider } from "../providers/MetaProvider";
import Footer from "./footer";

const Layout = ({ location, meta, labels, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  const { lang, data } = meta;
  return (
    <MetaProvider meta={{lang, ...data}} labels={labels}>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <Header/>
        <main id="main">{children}</main>
        <Footer/>
      </div>
    </MetaProvider>
  );
};

export default Layout;
