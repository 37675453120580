import * as React from "react";
import { graphql } from "gatsby";

import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import MapPageComponent from "../components/homepage/MapPageComponent";


const MapPageTemplate = ({ data, location }) => {

  const meta = data.prismicMeta || {};
  
  const labels = data.prismicMapPage.data || {};
  const schools = data.allAirtableSchools.edges.map(({ node }) => node) || [];
  schools.sort((a, b) => a.data.Name.localeCompare(b.data.Name));
  
  const projects = data.allAirtableProjects.edges.map(({ node }) => node) || [];
  projects.sort((a, b) => a.data.Name.localeCompare(b.data.Name));

  return (
    <Layout location={location} meta={meta} labels={labels}>
      <MapPageComponent
        data={{
          schools,
          projects,
          labels: data.prismicMapPage.data || {},
        }}
      />

    </Layout>
  );
};

export const Head = ({ data }) => {
  if(!data) return;
  const page_title = data.prismicMapPage?.data?.meta_title || null;
  const page_description = data.prismicMapPage?.data?.meta_description || null;
  const share_image = data.prismicMapPage?.data?.share_image?.url || null;
  return <Seo title={page_title} description={page_description} image={share_image}/>;
};

export const mapPageQuery = graphql`
  query mapPageQuery($lang: String) {
    prismicMapPage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        meta_title
        meta_description
        share_image {
          url
        }

        title {
          text
        }
        title_link {
          url
        }
        title_link_label
        schools_projects

        filter
        done
        search
        filter_by
        reset

        list_by_schools
        list_by_projects
        listing_schools_count
        listing_projects_count
      }
    }
    prismicMeta(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        ...MetaAllData
      }  
    }
    allAirtableSchools {
     edges {
        node {
          ...SchoolPartialDetails
        }
      }
    }
    allAirtableProjects {
      edges {
        node {
          ...ProjectPartialDetails
        }
      }
    }
  }
`;

export default withPrismicPreview(MapPageTemplate);
