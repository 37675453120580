import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import * as s from './Map.module.scss';
const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = process.env.GATSBY_GOOGLE_MAPS_API_KEY;


const defaultMapContainerStyle = {
  width: '100%',
  height: '400px',
};


const Map = (props) => {
  const { children } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
    libraries: lib,
    mapIds: idG,
  });
  
  
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={defaultMapContainerStyle}
      {...props}
    >
      {children}
    </GoogleMap>
  ) : 
  // Replace this with your loading component
  <div style={props.mapContainerStyle || defaultMapContainerStyle} className={s.SpinnerWrap}>
    <div className={s.Spinner}/>
  </div>

};

export default Map;