exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "map_page":
      return "/"
    case "custom_page":
      return `/${doc.uid}`;
    case "general_page":
      return `/${doc.uid}`;
    default:
      return "/";
  
  }

};
