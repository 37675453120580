const buttonClassnames = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  'Blue Button': 'btn-primary',
  'Plain': 'link',
  default: 'link'
};

function getBtnClassname(key) {
  return buttonClassnames[key || 'default'];
}

export default getBtnClassname;
