import React from 'react';
import getBtnClassname from '../constants/buttons';
import { PrismicLink } from '@prismicio/react';

const ActionLink = ({ link, label, type }) => {
  if(!link || !label) return null;
  return (
    <PrismicLink href={link} className={getBtnClassname(type)}>
      {label}
    </PrismicLink>
  );
};

export default ActionLink;

