import React from 'react';
import * as s from './grid.module.scss';

const GridClassNames = [
  s.Grid2,
  s.Grid1,
  s.Grid2,
  s.Grid3,
  s.Grid4,
  s.Grid5,
]
const Grid = ({ columns=2, children }) => {
  return (
    <div className={GridClassNames[columns] || GridClassNames[0]}>
      {children}
    </div>
  );
};

export default Grid;