import { PrismicLink } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as s from './footer.module.scss';
import { useMeta } from '../providers/MetaProvider';
import { socialHandle } from '../../utils/helperFunctions';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';

const Footer = () => {
  const {meta} = useMeta();

  return (
    <footer className={s.Footer}>
      <div className="Container">


        <div className={s.ColumnsWrap}>
          <div className={s.Column}> 
            <PrismicLink href={'/'}>
              <GatsbyImage 
                image={meta.site_logo?.gatsbyImageData} 
                alt={meta.site_logo?.alt || ''} 
              />
            </PrismicLink>
            <PrismicLink href={meta.copyright_link?.url} style={{marginTop: 'auto'}}>
              <GatsbyImage 
                image={meta.copyright?.gatsbyImageData} 
                style={{maxWidth: '150px'}}
                alt={meta.copyright?.alt || 'Copyright'} 
              />
            </PrismicLink>
          </div>
          {meta.column_a.length > 0 && meta.column_a[0].btn_link && (
            <div className={s.Column}>
              <span className={s.ColumnHeader}>{meta.column_a_label}</span>
              <div className={s.ColumnLinks}>
                {meta.column_a.map(({btn_link, btn_label}, index) => (
                  <PrismicLink className={s.ColumnItem} href={btn_link?.url} key={index}>
                    <p>{btn_label}</p>
                  </PrismicLink>
                ))}
              </div>
            </div>
          )}
          {meta.column_b.length > 0 && meta.column_b[0].btn_link && (
            <div className={s.Column}>
              <span className={s.ColumnHeader}>{meta.column_b_label}</span>
              <div className={s.ColumnLinks}>
                {meta.column_b.map(({btn_link, btn_label}, index) => (
                  <PrismicLink className={s.ColumnItem} href={btn_link?.url} key={index}>
                    <p>{btn_label || 'Test Link'}</p>
                  </PrismicLink>
                ))}
              </div>  
            </div>
          )}
          <div className={s.Column}>
            <span className={s.ColumnHeader}>{meta.social_column_label}</span>
            <div className={s.ColumnLinks}>
              <a className={s.ColumnItem} href={socialHandle(meta.instagram_handle, 'instagram')} > 
                <FaInstagram/> <p>{meta.instagram_handle}</p>
              </a>
              <a className={s.ColumnItem} href={socialHandle(meta.facebook_handle, 'facebook')} > 
                <FaFacebookSquare/> <p>{meta.facebook_handle}</p>
              </a>
              <a className={s.ColumnItem} href={socialHandle(meta.x_handle, 'twitter')} > 
                <RiTwitterXFill/><p>{meta.x_handle}</p> 
              </a>
       
            </div>  
          </div>
          <div className={s.Column}>
            <span className={s.ColumnHeader}>{meta.tig_column_label}</span>
            <PrismicLink href={meta.tig_link?.url}>
              <GatsbyImage 
                image={meta.tig_image?.gatsbyImageData} 
                alt={meta.tig_image?.alt || 'Taking It Global Logo'} 
                className={s.LogoInvert}
              />
            </PrismicLink>
          </div>
        </div>


      </div>
    </footer>
  );
};

export default Footer;