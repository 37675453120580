import React, { createContext, useContext, useEffect, useState } from 'react';
import { keyMergeProjects, keyMergeSchools } from '../../utils/airtableKeyMerge';
import {debounce} from 'lodash';

const FilterContext = createContext();
export const FilterProvider = ({ data, children }) => {
  const value = useFilterProvider(data);
  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};
export const useFilter = () => useContext(FilterContext);

const searchKeys = ['Name'];

function useFilterProvider(data) {
  const initialSchools = keyMergeSchools(data.schools);
  const initialProjects = keyMergeProjects(data.projects);

  const filterOptions = [
    { id: 'Values', options: [...new Set(initialSchools.map(project => project.Values).flat())].sort((a, b) => a.localeCompare(b)) },
    { id: 'Sdgs', options: [...new Set(initialSchools.map(project => project.Sdgs).flat())].sort((a, b) => a.localeCompare(b)) },
    { id: 'Regions', options: [...new Set(initialProjects.map(project => project.Regions).flat())].sort((a, b) => a.localeCompare(b))},
  ];

  const defaultFilters = filterOptions.reduce((acc, curr) => {
    acc[curr.id] = [];
    return acc;
  }, {});

  const [schools, setSchools] = useState(initialSchools);
  const [projects, setProjects] = useState(initialProjects);

  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState(defaultFilters);

  
  
  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ filters])
  
  useEffect(() => {
    const debouncedUpdate = debounce(updateData, 300);
    debouncedUpdate();
    return debouncedUpdate.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  
  async function updateData(){
    let tempSchools = [...initialSchools];
    let tempProjects = [...initialProjects];
    const searchedSchools = handleSearch(tempSchools, searchKeys, searchTerm);
    const searchedProjects = handleSearch(tempProjects, searchKeys, searchTerm);
    let filteredSchools = searchedSchools;
    let filteredProjects = searchedProjects;
    filterOptions.forEach(key =>{
      filteredSchools = handleFilter(filteredSchools, key.id, filters[key.id]);
      filteredProjects = handleFilter(filteredProjects, key.id, filters[key.id]);
    });
    setSchools(filteredSchools);
    setProjects(filteredProjects);
  }
  function handleFilterChange(key, value) {
    setFilters({
      ...filters,
      [key]: value.map(x => x.value),
    });
  }
  function handleSearchChange(e) {
    const { value } = e.target;
     setSearchTerm(value);
  }
  function handleClear() {
    setSearchTerm('');
    setFilters(defaultFilters);
  }
  const handleFilter = (data, key, values) => {
    if(!values || !values.length > 0) return data;
    const filteredData = data.filter(item => item[key].some(v => values.includes(v)));
    return filteredData;
  };
  
  const handleSearch = (data, keys, value) => {
    if(!value || !value.length > 0) return data;
    const searchedData = data.filter(item => {
      return keys.some(key => {
        return item[key].toLowerCase().includes(value.toLowerCase());
      });
    });
    return searchedData;
  };
  
  return {
    schools,
    projects,
    filters,
    searchTerm,
    handleClear,
    handleFilterChange,
    handleSearchChange,
    filterOptions,
  };
}
