import React, { Fragment, useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactPaginate from 'react-paginate';
import ProjectCard from '../cards/ProjectCard';
import SchoolCard from '../cards/SchoolCard';
import Grid from '../layout/grid';
import Container from '../layout/container';
import { useFilter } from '../providers/FilterProvider';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import * as s from './ListSection.module.scss';
import { useMeta } from '../providers/MetaProvider';

const ListSection = () => {
  const {schools, projects} = useFilter(); 
  const {labels} = useMeta();
  return (
    <section>
      <Container>
        <Tabs selectedTabClassName={s.tabSelected}>
          <div className={s.tabContainer}>
            <TabList className={s.tabList}> 
              <Tab className={s.tab}>{labels.list_by_schools}</Tab>
              <Tab className={s.tab}>{labels.list_by_projects}</Tab>
            </TabList>
          </div>
          <TabPanel>
            <ListPanel items={schools} type="schools" label={labels.list_schools_count}/>
          </TabPanel>
          <TabPanel>
            <ListPanel items={projects} type="projects" label={labels.list_projects_count}/>
          </TabPanel>
        </Tabs>
      </Container>  
    </section>
  );
};

const ListPanel = ({items, type, label}) => {
  const itemsPerPage = 9;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    if (itemOffset >= items.length) {
      setItemOffset((pageCount - 1) * itemsPerPage);
    }
  }, [items.length, pageCount, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };
  const availableLabel = label?.replace('{{x}}', items.length) || `${items.length} ${type} Available`;
  return(
    <div>
      <span aria-live="polite" id="results" className={s.resultsAmount}>{availableLabel}</span>
      <Grid columns={3}>
        {currentItems.map((item, index) => (
          <Fragment key={`${type} - ${index}`}>   
            {type === 'schools' ? 
              <SchoolCard school={item}/>
            :
              <ProjectCard project={item}/>
            }
          </Fragment>
        ))}
      </Grid>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FaChevronRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={9}
        pageCount={pageCount}
        previousLabel={<FaChevronLeft />}
        renderOnZeroPageCount={null}
        containerClassName={s.paginationList}
        pageClassName={s.paginationItem}
        activeClassName={s.paginationItemActive}
        previousClassName={s.paginationItemNext}
        nextClassName={s.paginationItemNext}
      />
    </div>
  )
}

export default ListSection;