import React from 'react'
import MapSection from './MapSection';
import ListSection from './ListSection';
import FilterBar from './FilterBar';
import { FilterProvider } from '../providers/FilterProvider';
import Container from '../layout/container';
import * as s from './MapSection.module.scss';
import { PrismicLink } from '@prismicio/react';
import { useMeta } from '../providers/MetaProvider';


const MapPageComponent = ({data}) => {
  const {schools, projects} = data;
  const {labels} = useMeta();
  return (
    <FilterProvider data={{schools, projects}}>
      <div>
        <Container>
          <div className={s.headingOverlay}>
            <h1>{labels.title?.text} <PrismicLink href={labels.title_link?.url}>{labels.title_link_label}</PrismicLink></h1>
          </div>
        </Container>

        <MapSection/>
        <FilterBar/>
        <ListSection/>
      
      </div>
    </FilterProvider>
  )
}

export default MapPageComponent;