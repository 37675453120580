import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const gatsbyExtensions = ['jpg','jpeg','png','webp','avif']
const isImage = (image) => {
  if(!image) return false;
  return image.localFiles && image.localFiles.length > 0;
}

const AirtableImage = ({ image,props }) => {
  if(!image || !isImage(image)) return null;

  const isGatsbyImage = gatsbyExtensions.includes(image.localFiles[0].extension);
  
  const url = isGatsbyImage ? getImage(image.localFiles[0].childImageSharp.gatsbyImageData) : image.localFiles[0].publicURL;

  return (
    <div>
      {isGatsbyImage ? (
        <GatsbyImage 
          image={url} 
          alt=""
          {...props }
        />
      ) : (
        <img 
          src={url}  
          style={{width: '100%', height: 'auto'}}
          alt=""
          {...props }
        />
      )}
    </div>
  );
};

export {AirtableImage, isImage};